<template>
  <div class="mt-4 table_container">


    <table class="table list-content">
      <thead class=" ">
      <tr>
        <th style="text-align: center;">Access Time
        </th>
        <th style="text-align: center;">
          Link
        </th>
        <th style="text-align: center;">
          IP
        </th>
        <th style="text-align: center;">
          Country
        </th>
        <th style="text-align: center;">
          OS
        </th>
        <th style="text-align: center;">
          Browser
        </th>
        <th style="text-align: center;">
          Referrer
        </th>

      </tr>
      </thead>

      <tbody class="list-content-body">

      <tr v-if="statistics.length == 0">
        <td colspan="9">
          <div class="col">
            <p class="no-rows text-center">
              <no-events-available></no-events-available>
            </p>
          </div>
        </td>
      </tr>
      <template v-else>
        <tr v-for="stats in statistics">
          <td>
            {{ parseVisitorDateTime(stats._source.visitor_time) }}
          </td>
          <td style="text-align: center;">
            <span class="shorten_id" v-tooltip="stats.shorten_link+stats._source.shorten_id">
              <a :href="stats.shorten_link+stats._source.shorten_id" target="_blank">{{ stats._source.shorten_id }}</a>
            </span>
            <skeleton v-if="stats.showMetaLoader" class="mt-1"></skeleton>
            <template v-else>
              <div>
                <img v-if="!stats.favicon || stats.favicon=='data:,'" class="meta-image" src="/assets/img/browsers/png/icons8-globe-48.png">
                <img v-else class="meta-image" :src="stats.favicon">
                <span class="meta-title">{{ stats.title }}</span>
              </div>
            </template>
          </td>
          <td style="text-align: center;">{{ stats._source.ip_address }}</td>
          <td style="text-align: center;">
            <img :src="computeCountryFlags(stats._source.country)" class="browser">
          </td>
          <td style="text-align: center;"><img :src="fetchOSIcon(stats._source.os_name)" class="browser"></td>
          <td style="text-align: center;"><img :src="fetchBrowserIcon(stats._source.browser)" class="browser"></td>
          <td style="text-align: center;">
            <div style="margin-left: 20px" class="row">
              <p class="referral">{{ stats._source.referral }}</p>
              <i class="far fa-question-circle custom_tooltip tag_tooltip" title="Platform">
                <div class="tool_tip_box">
                  <div class="inner_box">
                    <p>{{ stats._source.referral }}</p>
                  </div>
                </div>
              </i>
            </div>
          </td>
        </tr>
      </template>

      </tbody>

    </table>

    <pagination-next-prev v-if="statistics.length != 0" :length="length" :from="from" :size="size"
                          @update="fetchStatsByUserId($event)"></pagination-next-prev>
  </div>

</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default ({
  data() {
    return {
      statistics: [],
      from: 0,
      size: 10,
      temp_from: 0,
      length: 10,
    }
  },
  async mounted() {
    await this.fetchStatsByUserId(this.from)
  },
  props: {},
  computed: {},
  methods: {
    ...mapActions([
      'fetchAnalyticsByUserId',
      'fetchLinkMeta'
    ]),
    async fetchStatsByUserId(from = 0) {
      this.from = from
      let res = await this.fetchAnalyticsByUserId({from, size: this.size, datetime: 'visitor_time_desc'});
      if (res.length > 0) {
        res = res.map(d => ({...d, showMetaLoader: true}))
        this.temp_from = this.from
        await this.fetchLinksMeta(res)
      } else {
        this.from = this.temp_from
      }
    },
    async fetchLinksMeta(rawStats) {
      const statistics = [];
      rawStats = rawStats || []
      const ids = rawStats.map(link => link._source.shorten_id);
      const promises = ids.map(id => this.fetchLinkMeta(id))
      const allMeta = await Promise.all(promises);
      allMeta.forEach((res, index) => {
          const link = rawStats[index];
          link.favicon = res ? res.data.favicon_url : ''
          link.title = res ? res.data.meta.title : '-'
          link.shorten_link = res ? res.domain_name : ''
          if (link.shorten_link.slice(-1) != '/') link.shorten_link = link.shorten_link + '/'
          link.showMetaLoader = false
          statistics.push(link)
        })
      this.statistics = statistics;
    },
    fetchData(event) {
    }
  }
})

</script>
<style scoped lang="less">
.browser {
  width: 30px;
}

.meta-image {
  width: 17px;
  border-radius: 15px;
  margin-left: 5px;

}

.meta-title {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  padding-left: 5px;
  margin-top: 2px;
}

.shorten_id {
  background: #e4e4e4;
  padding: 2px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.referral {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
