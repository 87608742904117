<template>
  <div class="dashboard_slider flex flex-col h-full justify-center">
    <div class="VueCarousel featureSlider__carousel">
      <slick ref="slick" :options="slickOptions">
        <div v-if="false" class="bio_link_slider">
          <div style="background-image: url('/assets/img/v2/icons/Rectangle.svg')" class="px-8 flex font-poppins justify-around items-center gap-x-4 bg-contain bg-no-repeat bg-right">
            <div class="">
              <h2 class="text-[#3C4549] text-lg leading-none font-medium">Bio Link</h2>
              <p class="py-[1.25rem] text-[0.875rem] text-gray-500">A clickable URL  takes visitors to a separate website.</p>

              <Button
                id="login-button"
                type="button"
                class="bg-[#3C4549] hover:bg-[#F2F3F8]"
                buttonClass="btn-md"
              >
                <template v-slot:label>Create Now</template>
              </Button>
            </div>
            <img src="/assets/img/v2/mobile.svg"/>
          </div>
        </div>
        <div>
          <div class="flex font-poppins justify-around px-7 pt-7 gap-x-4 pb-4 items-center  items-center">
            <div>
              <h2 class="text-[#013D87] text-xl font-bold leading-none">Do you know?</h2>
              <p class="py-3 text-[0.875rem] text-gray-500">With our “<span class="text-[#3c4549] font-semibold">White Label</span>” feature you can make replug look like your own product :)</p>
              <div class="font-medium leading-none hover:underline hover:cursor-pointer"><em><RouterLink :to="`/${getWorkspace._id}/settings/white-label/general-settings`" class="text-blue-400"><em>Lets try it out.</em></RouterLink></em></div>
            </div>
            <img src="/assets/img/v2/questions.svg" />
          </div>
        </div>
        <div v-if="isSuperAdmin()">
          <div class="font-poppins text-[0.875rem] px-7 pt-6">
            <h2 class="text-[#3C4549] text-lg leading-none font-medium">Plan Info</h2>
            <div class="space-y-4 pt-4">
              <div class="w-full flex justify-between items-center">
                <div class="w-full text !text-[0.75rem] font-semibold !text-gray-500">Clicks</div>
                <b-progress :variant="computeLimitsProgressColor(subscription_detail.used_limits.clicks, subscription_detail.total_limits.clicks)" class="h-[0.6rem] w-60 opacity-80" :max="subscription_detail.total_limits.clicks" :value="subscription_detail.used_limits.clicks"></b-progress>
                <div class="w-full">
                  <p v-if="subscription_detail.total_limits.clicks < 100000" class="!text-[#3C4549] text !text-[0.75rem] text-right font-semibold">{{ numberFormatter(computeClickLimits(subscription_detail.used_limits.clicks, subscription_detail.total_limits.clicks)) }}/{{ subscription_detail.total_limits.clicks }}</p>
                  <p v-else class="!text-[#3C4549] text !text-[0.75rem] text-right font-semibold">{{ subscription_detail.used_limits.clicks }}/Unlimited</p>
                </div>
              </div>
              <div class="w-full flex justify-between items-center">
                <div class="w-full text !text-[0.75rem] font-semibold !text-gray-500">Workspace</div>
                  <b-progress class="w-full h-[0.6rem] w-60 opacity-80" :variant="computeLimitsProgressColor(subscription_detail.used_limits.brands, subscription_detail.total_limits.brands)" :value="subscription_detail.used_limits.brands" :max="subscription_detail.total_limits.brands"></b-progress>
                <div class="w-full">
                  <p v-if="subscription_detail.total_limits.brands < 1000" class="!text-[#3C4549] text-right text !text-[0.75rem] font-semibold">{{ subscription_detail.used_limits.brands }}/{{ subscription_detail.total_limits.brands }}</p>
                  <p v-else class="!text-[#3C4549] text-right text !text-[0.75rem] font-semibold">{{ subscription_detail.used_limits.brands }}/Unlimited</p>
                </div>
              </div>
              <div class="w-full flex justify-between items-center">
                <div class="w-full text !text-[0.75rem] font-semibold !text-gray-500">Campaigns</div>
                  <b-progress class="h-[0.6rem] w-60 opacity-80" :variant="computeLimitsProgressColor(subscription_detail.used_limits.campaigns, subscription_detail.total_limits.campaigns)" :value="subscription_detail.used_limits.campaigns" :max="subscription_detail.total_limits.campaigns"></b-progress>
                <div class="w-full">
                  <p v-if="subscription_detail.total_limits.campaigns < 1000" class="!text-[#3C4549] text-right  text !text-[0.75rem] font-semibold">{{ subscription_detail.used_limits.campaigns }}/{{ subscription_detail.total_limits.campaigns }}</p>
                  <p v-else class="!text-[#3C4549] text !text-[0.75rem] text-right font-semibold">{{ subscription_detail.used_limits.campaigns }}/Unlimited</p>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center pt-4 text-blue-400 font-semibold">
            <RouterLink :to="`/${getWorkspace._id}/settings/billing`" class="text-blue-400 hover:underline"><em>See Details</em></RouterLink>
          </div>
        </div>
      </slick>
    </div>
  </div>
</template>
<script>

import Button from '@/ui/ui-kit/v2/Button.vue'
import http from '@/mixins/http-lib'
import { fetchBillingDetails } from '@/config/api'
import { mapGetters } from 'vuex'

let mapGetter
export default {
  name: 'Carousel',
  components: { Button },
  data () {
    return {
      slickOptions: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchMove: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      subscription_detail: {
        total_limits: {
          clicks: 0,
          brands: 0,
          campaigns: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getWorkspace', 'getProfile'])
  },
  async created () {
    if(this.isSuperAdmin()) {
      await this.fetchBillingDetails()
    }
  },
  methods: {
    async fetchBillingDetails() {
      let date = {
        label: ' This Month',
        value: this.getCurrentMonthDateRange()
      }
      return await http.post(fetchBillingDetails, {date}).then(res => {
        if (res.data.status) {
          this.subscription_detail = res.data.data
        }
        return true
      }).catch(err => {
        return err
      })
    },
  }
}
</script>
<style lang="less" scoped>

  @media (min-width: 1280px) {
    .bio_link_slider {
      display: none !important;
    }
  }
</style>
