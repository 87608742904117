<template>
  <div class="dashboard_pie_chart" ref="dashboard_pie_chart"></div>
</template>
<script>
import echarts from 'echarts'

export default {
  props: ['totalClicks', 'uniqueClicks', 'totalConversions'],
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item'
        },
        color: ['#0165e1', '#f9bc4d', '#3aa956'],
        textStyle: {
          fontFamily: 'poppins',
          fontSize: 13,
          fontWeight: 500,
          textAlign: 'center'
        },
        series: [
          {
            name: 'Analytics',
            type: 'pie',
            radius: ['30%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.totalClicks, name: 'Clicks' },
              { value: this.uniqueClicks, name: 'Unique Clicks' },
              { value: this.totalConversions, name: 'Conversion' },
            ]
          }
        ],
      }
    }
  },
  mounted () {
    // Get a reference to the chart container
    const chartContainer = this.$refs.dashboard_pie_chart;

    // Initialize ECharts instance
    const chart = echarts.init(chartContainer);

    // Set the ECharts options
    chart.setOption(this.option);

    // Resize the chart when the window is resized
    window.addEventListener('resize', async () => {
      await chart.resize();
    });
  },
}
</script>
<style lang="less">
.dashboard_pie_chart {
  width: 100%;
  height: 220px;
  margin-top: -20px;
}
</style>
