<template>
  <div class="youtube_slider  flex flex-col justify-center h-full">
    <slick ref="slick" :options="slickOptions">
      <div>
        <div class="w-[14rem] grid_box_shadow rounded-bl-lg rounded-br-lg">
          <div>
            <img class="h-[123px] w-[14rem] rounded-tl-lg rounded-tr-lg" src="https://i.ytimg.com/vi_webp/A98Gs3ruCpI/maxresdefault.webp" />
            <div class="px-2 py-[0.5rem] gap-x-2 flex justify-between items-start">
              <img class="w-4" src="/assets/img/v2/icons/replug_logo.png">
              <div class="">
                <p class="text-[#3C4549] text-[0.7rem] font-medium">Boost Your E-Commerce Salas Wih Deep Links | Replug.io</p>
                <p class="text-[0.6rem] text-gray-500">Replug Official " 3 months ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="w-[14rem] grid_box_shadow rounded-bl-lg rounded-br-lg">
          <div>
            <img class="h-[123px] w-[14rem] rounded-tl-lg rounded-tr-lg" src="https://i.ytimg.com/vi_webp/A98Gs3ruCpI/maxresdefault.webp" />
            <div class="px-2 py-[0.5rem] gap-x-2 flex justify-between items-start">
              <img class="w-4" src="/assets/img/v2/icons/replug_logo.png">
              <div class="">
                <p class="text-[#3C4549] text-[0.7rem] font-medium">Boost Your E-Commerce Salas Wih Deep Links | Replug.io</p>
                <p class="text-[0.6rem] text-gray-500">Replug Official " 3 months ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="w-[14rem] grid_box_shadow rounded-bl-lg rounded-br-lg">
          <div>
            <img class="h-[123px] w-[14rem] rounded-tl-lg rounded-tr-lg" src="https://i.ytimg.com/vi_webp/A98Gs3ruCpI/maxresdefault.webp" />
            <div class="px-2 py-[0.5rem] gap-x-2 flex justify-between items-start">
              <img class="w-4" src="/assets/img/v2/icons/replug_logo.png">
              <div class="">
                <p class="text-[#3C4549] text-[0.7rem] font-medium">Boost Your E-Commerce Salas Wih Deep Links | Replug.io</p>
                <p class="text-[0.6rem] text-gray-500">Replug Official " 3 months ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="w-[14rem] grid_box_shadow rounded-bl-lg rounded-br-lg">
          <div>
            <img class="h-[123px] w-[14rem] rounded-tl-lg rounded-tr-lg" src="https://i.ytimg.com/vi_webp/A98Gs3ruCpI/maxresdefault.webp" />
            <div class="px-2 py-[0.5rem] gap-x-2 flex justify-between items-start">
              <img class="w-4" src="/assets/img/v2/icons/replug_logo.png">
              <div class="">
                <p class="text-[#3C4549] text-[0.7rem] font-medium">Boost Your E-Commerce Salas Wih Deep Links | Replug.io</p>
                <p class="text-[0.6rem] text-gray-500">Replug Official " 3 months ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="w-[14rem] grid_box_shadow rounded-bl-lg rounded-br-lg">
          <div>
            <img class="h-[123px] w-[14rem] rounded-tl-lg rounded-tr-lg" src="https://i.ytimg.com/vi_webp/A98Gs3ruCpI/maxresdefault.webp" />
            <div class="px-2 py-[0.5rem] gap-x-2 flex justify-between items-start">
              <img class="w-4" src="/assets/img/v2/icons/replug_logo.png">
              <div class="">
                <p class="text-[#3C4549] text-[0.7rem] font-medium">Boost Your E-Commerce Salas Wih Deep Links | Replug.io</p>
                <p class="text-[0.6rem] text-gray-500">Replug Official " 3 months ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="w-[14rem] grid_box_shadow rounded-bl-lg rounded-br-lg">
          <div>
            <img class="h-[123px] w-[14rem] rounded-tl-lg rounded-tr-lg" src="https://i.ytimg.com/vi_webp/A98Gs3ruCpI/maxresdefault.webp" />
            <div class="px-2 py-[0.5rem] gap-x-2 flex justify-between items-start">
              <img class="w-4" src="/assets/img/v2/icons/replug_logo.png">
              <div class="">
                <p class="text-[#3C4549] text-[0.7rem] font-medium">Boost Your E-Commerce Salas Wih Deep Links | Replug.io</p>
                <p class="text-[0.6rem] text-gray-500">Replug Official " 3 months ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="w-[14rem] grid_box_shadow rounded-bl-lg rounded-br-lg">
          <div>
            <img class="h-[123px] w-[14rem] rounded-tl-lg rounded-tr-lg" src="https://i.ytimg.com/vi_webp/A98Gs3ruCpI/maxresdefault.webp" />
            <div class="px-2 py-[0.5rem] gap-x-2 flex justify-between items-start">
              <img class="w-4" src="/assets/img/v2/icons/replug_logo.png">
              <div class="">
                <p class="text-[#3C4549] text-[0.7rem] font-medium">Boost Your E-Commerce Salas Wih Deep Links | Replug.io</p>
                <p class="text-[0.6rem] text-gray-500">Replug Official " 3 months ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="w-[14rem] grid_box_shadow rounded-bl-lg rounded-br-lg">
          <div>
            <img class="h-[123px] w-[114rem] rounded-tl-lg rounded-tr-lg" src="https://i.ytimg.com/vi_webp/A98Gs3ruCpI/maxresdefault.webp" />
            <div class="px-2 py-[0.5rem] gap-x-2 flex justify-between items-start">
              <img class="w-4" src="/assets/img/v2/icons/replug_logo.png">
              <div class="">
                <p class="text-[#3C4549] text-[0.7rem] font-medium">Boost Your E-Commerce Salas Wih Deep Links | Replug.io</p>
                <p class="text-[0.6rem] text-gray-500">Replug Official " 3 months ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slick>
  </div>
</template>
<script>

import Button from '@/ui/ui-kit/v2/Button.vue'

export default {
  name: 'Carousel',
  components: { Button },
  data () {
    return {
      slickOptions: {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchMove: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1860,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1370,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      }
    }
  }
}
</script>
<style lang="less" scoped>

</style>
