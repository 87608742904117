<template>

  <div class="dashboard_component" v-if="getProfile._id">
    <UTMComponentV2 ref="UTMModal"></UTMComponentV2>
    <CreateLinkV2 ref="create_link_v2" :isCallTypeDashboard="true"></CreateLinkV2>
    <div>

        <!-- Dashboard body -->
        <div class="pb-6 pt-[84px] px-6 w-full bg-[#F7F7FC]">
          <div class="first_grid_container">
            <div class="inner_box dashed_border rounded-[0.5rem]">
              <div class="quick_shorten_link_section h-full justify-between items-center gap-16 flex">
                <div class="px-10 4xl:pl-16 w-full">
                  <h2 class="font-poppins text-zinc-700 text-[1.25rem] font-semibold leading-3">Quick Shorten your link</h2>
                  <InputButton
                    class="pt-4"
                    id="password"
                    v-model="getSaveLink.url"
                    type="text"
                    label="Enter URL of the webpage here"
                    iconName="lock.svg"
                    @blur="getSaveLink.url = focusoutConcatenation(getSaveLink.url)"
                    customClass="h-[2.875rem] bg-white border-0 !pr-36"
                    :errorType="validations.url || validations.urlValid || validations.urlLength  ? 'danger' : ''"
                  >
                    <template v-slot:error_message>
                      <InputFieldMessage class="mr-14" v-if="validations.url" :message="messages.url"></InputFieldMessage>
                      <InputFieldMessage class="mr-14" v-else-if="validations.urlLength" :message="messages.urlLength"></InputFieldMessage>
                      <InputFieldMessage class="mr-14" v-else-if="validations.urlValid" :message="messages.urlValid"></InputFieldMessage>
                    </template>
                    <template v-if="getSaveLink.url && getSaveLink.url.length > 8 && !validations.urlValid" v-slot:right_opt>
                      <button @click="$refs.UTMModal.resetUTMValidations(); $bvModal.show('modal-create-utm-v2')"  type="button" class="bg-white border-0 h-[2.875rem] text-[0.875rem] px-3 font-poppins leading-none text-blue-700">+ Add UTM</button>
                    </template>
                    <template v-slot:right_btn>
                      <button @click="createLink()" type="button" class="h-[2.875rem] font-poppins px-3 bg-blue-700 rounded-tr-lg rounded-br-lg">
                        <img v-if="!linkCreationLoader" class="w-3 h-2.5" src="/assets/img/v2/icons/right.svg" />
                        <Loader color="!w-3 !h-2.5 text-white" v-else></Loader>
                      </button>
                    </template>
                  </InputButton>
                </div>
                <div class="hidden xl:block">
                  <img class="w-96" src="/assets/img/v2/dashboard.svg" />
                </div>
              </div>
            </div>
            <div style="background-image: url('/assets/img/v2/icons/Rectangle.svg')" class="hidden 4xl:block inner_box grid_box_shadow grid_box_border font-poppins bg-contain bg-no-repeat bg-right bg-[#FCFDFE] rounded-[0.5rem]">
              <div class="flex flex-col h-full justify-center items-center">
                <div class="flex justify-around items-center gap-x-4">
                  <div class="">
                    <h2 class="text-[#3C4549] text-lg leading-none font-medium">Bio Link</h2>
                    <p class="py-[1.25rem] text-[0.875rem] w-40 text-gray-500">A clickable URL  takes visitors to a separate website.</p>

                    <Button
                      id="login-button"
                      type="button"
                      class="bg-[#3C4549]"
                      buttonClass="btn-md"
                      @click="createBioLink"
                    >
                      <template v-slot:label>Create Now</template>
                    </Button>
                  </div>
                  <img src="/assets/img/v2/mobile.svg"/>
                </div>
              </div>
            </div>
            <div class="inner_box grid_box_shadow grid_box_border bg-[#FCFDFE] rounded-[0.5rem]">
              <InfoSlider/>
            </div>
          </div>
          <div class="second_grid_container font-poppins pt-6">
            <ArchiveLinkV2></ArchiveLinkV2>
            <DeleteLinkV2></DeleteLinkV2>
            <div class="inner_box pb-[1rem] grid_box_shadow bg-[#FCFDFE] rounded-[0.5rem]">
              <div class="flex flex-col justify-between h-full">
                <div :class="{'border-b': getLinks.total_items > getLinks.limit}" class="overflow-y-scroll hide-scrollbar h-full border-[#ECEEF5]">
                  <!-- LinksFiltersV2 Component -->
                  <LinksFiltersV2></LinksFiltersV2>

                  <!-- Table Body Section -->
                  <LinksTableV2 @click="removeLink"></LinksTableV2>
                </div>
              </div>
            </div>

            <div class="inner_box p-6 rounded-[0.5rem] dashed_border">
              <div class="h-full flex flex-col justify-between">
                <div class="flex justify-between items-center">
                  <p class="text-[#3C4549] text-[1rem] font-medium">Clicks Analytics</p>
                  <DatePicker
                    className="dashboard_date_filter"
                    customClass="without_border_transparent !w-[8rem]"
                    @input="dateFilter"
                  ></DatePicker>
                </div>
                <PieChart
                  :totalClicks="commaSeparatedNumber(totalClicks)"
                  :uniqueClicks="commaSeparatedNumber(uniqueClicks)"
                  :totalConversions="commaSeparatedNumber(totalConversions)"
                />
                <div class="space-y-6 px-6">
                  <div class="justify-between items-center w-full flex">
                    <div class="justify-start items-center gap-3 flex">
                      <div class="w-[15px] h-[15px] bg-blue-700 rounded-sm"></div>
                      <div class="text-gray-500 text-[0.875rem] font-medium leading-[10px]">Clicks</div>
                    </div>
                    <div class="text-[#3C4549] text-lg font-bold leading-[10px]">{{ commaSeparatedNumber(totalClicks) }}</div>
                  </div>
                  <div class="justify-between items-center w-full flex">
                    <div class="justify-start items-center gap-3 flex">
                      <div class="w-[15px] h-[15px] bg-[#F9BC4D] rounded-sm"></div>
                      <div class="text-gray-500 text-[0.875rem] font-medium leading-[10px]">Unique Clicks</div>
                    </div>
                    <div class="text-[#3C4549] text-lg font-bold leading-[10px]">{{ commaSeparatedNumber(uniqueClicks) }}</div>
                  </div>
                  <div class="justify-between items-center w-full flex">
                    <div class="justify-start items-center gap-3 flex">
                      <div class="w-[15px] h-[15px]  bg-green-500 rounded-sm"></div>
                      <div class="text-gray-500 text-[0.875rem] font-medium leading-[10px]">Conversion</div>
                    </div>
                    <div class="text-[#3C4549] text-lg font-bold leading-[10px]">{{ commaSeparatedNumber(totalConversions) }}</div>
                  </div>
                </div>

                <RouterLink :to="`/${getWorkspace._id}/analytics/analytics`" class="text-center cursor-pointer font-medium font-poppins text-[#3C4549]"><em>See Details</em></RouterLink>
              </div>
            </div>
          </div>
          <div class="third_grid_container font-poppins pt-6">
            <div class="inner_box dashed_border rounded-[0.5rem]">
              <div class="h-full flex flex-col justify-center px-4">
                <div class="flex justify-around gap-x-4 items-center">
                  <div>
                    <h2 class="text-[#3C4549] text-[1rem] leading-none font-medium">Chrome Extension</h2>
                    <p class="py-2 w-36 text-gray-500">Replug makes your work easy and efficient by its chrome extension.</p>
                    <Button
                      id="login-button"
                      type="button"
                      class="bg-[#3C4549]"
                      buttonClass="btn-md"
                      @click="openChromeInstallLink()"
                    >
                      <template v-slot:label>Install Now</template>
                    </Button>
                  </div>
                  <img src="/assets/img/v2/icons/chrome.svg" />
                </div>
              </div>
            </div>
            <div class="inner_box w-full grid_box_shadow bg-[#FCFDFE] rounded-[0.5rem]">
              <div class=" h-full">
                <YoutubeSlider/>
              </div>
            </div>
            <div class="inner_box grid_box_shadow bg-[#FCFDFE] rounded-[0.5rem]">
              <div class="h-full flex flex-col justify-center items-center">
                <img class="pl-8 mr-auto" src="/assets/img/v2/call_center_character.svg" />
                <div class="flex pt-3 gap-x-2 justify-between items-center">
                  <Button
                    id="login-button"
                    type="button"
                    class="bg-[#3C4549]"
                    buttonClass="btn-md"
                  >
                    <template v-slot:label>Chat with us</template>
                  </Button>
                  <Button
                    id="login-button"
                    type="button"
                    class="border border-gray-500 hover:bg-[#F2F3F8]"
                    buttonClass="btn-md-outline"
                  >
                    <template v-slot:label>Book a demo</template>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end -->

        <div v-if="false" class="container">

          <guided-tour v-if="getGuidedTour.step1 && !getSiteDetails.isWhiteLabel"></guided-tour>
          <guided-tour v-if="getGuidedTour.step4 && !getSiteDetails.isWhiteLabel"></guided-tour>
          <template v-if="!getSiteDetails.isWhiteLabel && getProfile.username && getProfile.onboarding === false">
            <initial-onboarding-view></initial-onboarding-view>
          </template>
          <template v-else>
            <div class="dashboard_component_inner">
              <!--//old box style-->
              <onboarding-getting-started-steps v-if="!getSiteDetails.isWhiteLabel"></onboarding-getting-started-steps>
              <!--v-if="hideBoxStatus"-->
              <!--//for chrome extension-->



              <chrome-extension-notification
                v-if="getProfile && getProfile.triggers && getProfile.policy && getProfile.policy.can_add_links && !getSiteDetails.isWhiteLabel"></chrome-extension-notification>
              <div class="quick_link_section" v-if="getProfile && getProfile.policy && getProfile.policy.can_add_links">
                <div class="white_box ">
                  <div class="box_inner">
                    <div class="box_head d-flex align-items-center">
                      <div class="left">
                        <h2>Create Quick {{ getSiteDetails.agency_name }} Link</h2>
                      </div>
                      <div class="right ml-auto">
                        <!--<quick-link-action location="top"></quick-link-action>-->

                      </div>
                    </div>
                    <div class="box_content">
                      <quick-link-action/>
                      <template>

                        <!--                                            <create-quick-link location="dashboard_shortner_form"></create-quick-link>-->
                      </template>

                    </div>
                  </div>
                </div>
              </div>

              <overview-statistics :stats="stats" :statistics="overview"
                                   :loader="loader"></overview-statistics>

              <!--//chart block-->
              <div class="click_performance pt-4">
                <div class="white_box ">
                  <div class="box_inner">
                    <div class="box_head d-flex align-items-center">
                      <div class="left">
                        <h2>Clicks Performance</h2>
                      </div>
                      <!--<div class="right ml-auto">
                          <b-dropdown ref="timeLineDropdown" right
                                      class="ml-2  dropdown-menu-right default_style_dropdown"
                                      :no-caret="true">
                              &lt;!&ndash;d-flex align-items-center&ndash;&gt;
                              <div class="text-center dropdown_header  rounded_shape  d-flex align-items-center"
                                   slot="button-content">
                                  <div class="text">
                                      <p class="text-capitalize">{{dropdownValue}}</p>
                                  </div>
                                  <span class="arrow_icon ml-auto">
                              <i class=" fal fa-angle-down ml-3"></i>
                          </span>
                              </div>
                              <ul class="clear inner">
                                  <li @click="dropdownValue = 'Daily',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-day mr-2"></i> Daily
                                  </li>
                                  <li @click="dropdownValue = 'Weekly',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-week mr-2"></i> Weekly
                                  </li>
                                  <li @click="dropdownValue = 'Monthly',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-alt mr-2"></i> Monthly
                                  </li>
                                  <li @click="dropdownValue = 'Last 6 Months',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-alt mr-2"></i>Last 6 Months
                                  </li>
                                  <li @click="dropdownValue = 'All Time',$refs.timeLineDropdown.hide(true)"
                                      class="list_item_li">
                                      <i class="fal fa-calendar-check mr-2"></i>All Time
                                  </li>


                              </ul>
                          </b-dropdown>
                      </div>-->

                    </div>
                    <div class="box_content">
                      <div class="statistics_graph" v-if="loader">
                        <clip-loader :color="color" :size="size"></clip-loader>
                      </div>
                      <template v-else-if="this.activity.clicks">
                        <highcharts :options="clicksChart" ref="highcharts"></highcharts>
                      </template>
                      <template v-else>
                        <no-events-available></no-events-available>
                      </template>
                    </div>
                  </div>
                </div>
              </div>


              <div class="conversions_performance pt-4">
                <div class="white_box ">
                  <div class="box_inner">
                    <div class="box_head d-flex align-items-center">
                      <div class="left">
                        <h2>Conversions Performance</h2>
                      </div>

                    </div>
                    <div class="box_content">
                      <div class="statistics_graph" v-if="loader">
                        <clip-loader :color="color" :size="size"></clip-loader>
                      </div>
                      <template v-else-if="this.activity.conversions">
                        <highcharts :options="conversionsChart" ref="highcharts"></highcharts>
                      </template>
                      <template v-else>
                        <no-events-available></no-events-available>

                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- We need to remove this if condition in future -->
              <!-- filter bar chart -->
              <simple-bar-chart :paginationChunk="10" :loading="simpleBarLoader" :stats="referralStats" class="pr-3"
                                :title="'Referrals'"></simple-bar-chart>

              <!-- simple bar chart -->
              <div class="d-flex align-items-start">
                <simple-bar-chart :paginationChunk="5" :loading="simpleBarLoader" :stats="deviceTypeStats" class="pr-3"
                                  :title="'Device OS'"></simple-bar-chart>
                <simple-bar-chart :paginationChunk="5" :loading="simpleBarLoader" :stats="browserStats" class="pr-3"
                                  :title="'Browser'"></simple-bar-chart>
                <simple-bar-chart :paginationChunk="5" :loading="simpleBarLoader" :stats="countryStats"
                                  :title="'Country'"></simple-bar-chart>
              </div>


              <div v-if="false" class="d-flex align-items-start">
                <div class="performance_charts visitor_refferal_chart pr-3 pt-4">
                  <div class="white_box ">
                    <div class="box_inner">
                      <div class="box_head d-flex align-items-center">
                        <div class="left">
                          <h2>Countries</h2>
                        </div>

                      </div>
                      <div class="box_content">
                        <div class="statistics_graph" v-if="loader">
                          <clip-loader :color="color" :size="size"></clip-loader>
                        </div>

                        <template v-else>
                          <template v-if="countries_item > 0">
                            <highcharts :options="pieDataCountry"
                                        ref="countryChart"></highcharts>
                          </template>
                          <template v-else>
                            <no-events-available></no-events-available>
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="false" class="performance_charts conversions_performance pl-3 pt-4">
                  <div class="white_box ">
                    <div class="box_inner">
                      <div class="box_head d-flex align-items-center">
                        <div class="left">
                          <h2>Visitor Referral</h2>
                        </div>

                      </div>
                      <div class="box_content">
                        <div class="statistics_graph" v-if="loader">
                          <clip-loader :color="color" :size="size"></clip-loader>
                        </div>

                        <template v-else>
                          <template v-if="referral_item > 0">
                            <highcharts :options="pieDataReferral"
                                        ref="referralChart"></highcharts>
                          </template>
                          <template v-else>
                            <no-events-available></no-events-available>
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="conversions_performance pt-4" v-if="showDetailView">
                <div class="white_box ">
                  <div class="box_inner">
                    <div class="box_head d-flex align-items-center">
                      <div class="left">
                        <h2>Recent Activity</h2>
                      </div>

                    </div>
                    <div class="box_content">
                      <template>
                        <detail-table-list></detail-table-list>
                      </template>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </template>
        </div>
      </div>
  </div>

</template>

<script>
import { statisticsURL, userActivityURL } from '../../config/config.js'
import Header from '@/components/Header.vue'
import OverviewStatistics from './dashboard/OverviewStatistics'
import ChromeExtensionNotification from './onboarding/ChromeExtensionNotification'
import Sidebar from '@/components/Sidebar.vue'
import DetailTableList from '@/views/pages/analytics/detail-view/tables/DetailTableList'

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { mapActions, mapGetters } from 'vuex'

import GuidedTour from '../guided-tour/GuidedTour.vue'

import Dropdown from '@/ui/Dropdown.vue'
import ApplyUTMLink from '@/views/pages/setting/utm/ApplyUTMLink'
import ConfirmationBox from '@/ui/ConfirmationBox.vue'
import CreateQuickLink from '@/views/pages/links/CreateQuickLink'
import QuickLinkAction from '@/views/pages/links/QuickLinkAction'
import { bioLinkTypes, linkTypes } from '@/state/modules/mutation-types'
import {
  ANALYTIC_TYPE,
  DARK_PURPLE_LOADER_COLOR,
  overviewOfClicksPerformance,
  overviewOfConversionsPerformance,
  pieVisitedCountriesChart,
  pieVisitedFromChart
} from '../../common/constants'
import { JWT_CHROME_LOCAL_STORAGE } from '@/constants'
import ApplyUTMModal from '@/views/pages/setting/utm/ApplyUTMModal'
import SimpleBarChart from '@/components/custom-bar-charts/SimpleBarChart'
import FilterBarChart from '@/components/custom-bar-charts/FilterBarChart'
import InputButton from '@/ui/ui-kit/v2/InputButton.vue'
import Button from '@/ui/ui-kit/v2/Button.vue'
import InfoSlider from '@/components/dashboard/InfoSlider'
import YoutubeSlider from '@/components/dashboard/YoutubeSlider'
const InitialOnboardingView = () => import('./onboarding/InitialOnboardingView')
const OnboardingGettingStartedSteps = () => import('./onboarding/OnboardingGettingStartedSteps')
// import DnsModal from '../ui/DnsModal.vue'

import PieChart from '@/components/dashboard/PieChart.vue'
import { replugLinksValidation, URLMessages } from '@/common/attributes'
import http from '@/mixins/http-lib'
import { fetchBillingDetails } from '@/config/api'

export default {
  components: {
    PieChart,
    Button,
    Sidebar,
    InputButton,
    'header-bar': Header,
    GuidedTour,
    ClipLoader,
    Dropdown,
    ConfirmationBox,
    'apply-utm-link': ApplyUTMLink,
    CreateQuickLink,
    QuickLinkAction,
    InitialOnboardingView,
    OnboardingGettingStartedSteps,
    ChromeExtensionNotification,
    OverviewStatistics,
    DetailTableList,
    'apply-utm-modal': ApplyUTMModal,
    // DnsModal
    'simple-bar-chart': SimpleBarChart,
    'filter-bar-chart': FilterBarChart,
    InfoSlider,
    YoutubeSlider,

    LinksFiltersV2: () => import('@/views/pages/links/filters/LinksFiltersV2.vue'),
    ArchiveLinkV2: () => import('@/views/pages/links/dialogs/ArchiveLinkV2.vue'),
    DeleteLinkV2: () => import('@/views/pages/links/dialogs/DeleteLinkV2.vue'),
    LinksTableV2: () => import('@/views/pages/links/links-component/LinksTableV2.vue'),
    UTMComponentV2: () => import('@/views/pages/links/links-component/UTMComponentV2.vue'),
    CreateLinkV2: () => import('@/views/pages/links/dialogs/CreateLinkV2.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    DatePicker: () => import('@/ui/ui-kit/v2/DatePicker.vue'),
  },
  async created () {
    if (this.$route.query && this.$route.query.token) {
      localStorage.setItem(JWT_CHROME_LOCAL_STORAGE, this.$route.query.token)
    }

    // this.statistics()
    await this.detailView()
  },

  data: function () {
    return {
      activity: {
        clicks: true,
        conversions: true
      },
      dropdownValue: 'Daily',
      overview: {},
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      size1: '14px',
      color1: '#fff',
      loader: true,
      active_input: false,
      stats: {
        brands: 0,
        links: 0,
        visitors: 0,
        clicks: 0,
        unique_visitors: 0,
        cta: 0,
        pixels: 0,
        custom_domains: 0,
        last_month: 0,
        this_month: 0,
        yesterday: 0,
        today: 0
      },
      countries_item: 0,
      referral_item: 0,

      pieDataReferral: pieVisitedFromChart(),
      pieDataCountry: pieVisitedCountriesChart(),
      clicksChart: overviewOfClicksPerformance(),
      conversionsChart: overviewOfConversionsPerformance(),
      showDetailView: false,
      countryStats: [],
      browserStats: [],
      deviceTypeStats: [],
      referralStats: [],
      simpleBarLoader: true,
      deleteLink: {},
      linkCreationLoader: false,
      validations: replugLinksValidation,
      messages: URLMessages
    }
  },
  computed: {
    ...mapGetters(['getSubscription', 'getGuidedTour', 'getProfile', 'getQuickLink',
      'getLinks',
      'getLinksLoaders',
      'getCampaignLoaders',
      'getSaveLink',
      'getAnalyticsFilters',
      'getWorkspace'
    ]),
    totalClicks () {
      return this.overview && this.overview.hits && this.overview.hits.total ? this.overview.hits.total : 0
    },
    totalConversions () {
      return this.overview && this.overview.aggregations ? this.overview.aggregations.clicked_conversions.doc_count : 0
    },
    uniqueClicks () {
      return this.overview && this.overview.aggregations && this.overview.aggregations.unique_clicks ? this.overview.aggregations.unique_clicks.value : 0
    },
  },
  mounted () {
    window.addEventListener('scroll', this.scroll)
  },
  methods: {
    ...mapActions(['setAllCallToActionsFetchStatus']),
    openChromeInstallLink() {
      window.open('https://chromewebstore.google.com/detail/replug-url-shortener-link/mofbjpedmdgclbmpgjoboblbppnmgpol', '_blank')
    },
    createBioLink() {
      this.$store.commit(bioLinkTypes.RESET_BIO_LINK_ITEM)
      this.$store.commit(bioLinkTypes.RESET_BIO_CONTENT_SECTION_ADD)
      this.$store.commit(bioLinkTypes.SET_BIO_ADD_IS_CREATING_BIO, true)
      this.$router.push({name: 'select_content'})
    },
    async dateFilter(event) {
      this.getAnalyticsFilters.date = event
      await this.detailView()
    },
    async createLink() {
      this.linkCreationLoader = true
      await this.$refs.create_link_v2.store()
      this.linkCreationLoader = false
    },
    removeLink(data) {
      this.deleteLink = data
    },
    async detailView() {
      this.loader = true

      let res = await this.$store.dispatch('detailViewStatistics')
      this.overview = (res.data.status) ? res.data.data : {}

      this.loader = false
    },
    async performance () {
      // displaying loaders
      this.loader = true
      this.simpleBarLoader = true

      let res = await this.$store.dispatch('showStatistics')
      this.overview = (res.data.status) ? res.data.data : {}
      // prepare the payload for the charts
      if (res.data.data) {
        let performanceHistory = res.data.data.aggregations.performance_history.buckets
        this.countryStats = await this.processBarChartsStats(res.data.data.aggregations.countries.buckets, true)
        this.browserStats = await this.processBarChartsStats(res.data.data.aggregations.visited_from_browsers.buckets)
        this.deviceTypeStats = await this.processBarChartsStats(res.data.data.aggregations.visited_from_os.buckets, false, 'fetchOSIcon')
        this.referralStats = await this.processBarChartsStats(res.data.data.aggregations.visited_from_domains.buckets)
        this.performanceChart(performanceHistory, this.activity, this.conversionsChart, this.clicksChart)

        if (res.data.data.aggregations) {
          let aggregations = res.data.data.aggregations
          this.countriesChart(aggregations)
          this.visitedFromDomainsChart(aggregations)
        }
      }

      // disabling loaderss
      this.loader = false
      this.simpleBarLoader = false
    },
    visitedFromDomainsChart (aggregations) {
      let visitedFromDomains = []

      aggregations.visited_from_domains.buckets.forEach(item => {
        visitedFromDomains.push([item.key, item.doc_count])

      })
      this.referral_item = visitedFromDomains.length

      this.pieDataReferral.series =
        [{
          name: 'Visits',
          data: visitedFromDomains
        }]

    },
    countriesChart (aggregations) {
      let countries = []

      aggregations.countries.buckets.forEach(item => {
        countries.push([item.key, item.doc_count])

      })
      this.countries_item = countries.length
      this.pieDataCountry.series =
        [{
          name: 'Country',
          data: countries
        }]
    },
    statistics () {
      this.$http.post(statisticsURL).then(
        response => {
          if (this.processAjaxResponse(response)) {
            if (response.data.status) {
              this.stats = response.data.stats
              let clicks = []
              let conversions = []
              response.data.performance.forEach(function (element) {
                clicks.push([Date.parse(element.key_as_string), element.doc_count])
                conversions.push([Date.parse(element.key_as_string), element.click_true.doc_count])
              })
              let countries_chart = []
              response.data.visitorCountries.forEach(function (element) {
                countries_chart.push([element.key, element.doc_count])
              })
              this.countries_item = countries_chart.length
              let visitorReferral = []
              response.data.visitorReferral.forEach(function (element) {
                visitorReferral.push([element.key, element.doc_count])
              })
              this.referral_item = visitorReferral.length

              this.pieDataReferral.series =
                [{
                  name: 'Visits',
                  data: visitorReferral
                }]
              this.pieDataCountry.series =
                [{
                  name: 'Visits',
                  data: countries_chart
                }]

              this.clicksChart.series = [{
                name: 'Clicks',
                data: clicks,
                color: '#d2e3fd',
                shadow: {
                  width: 0
                }
              }]
              this.conversionsChart.series = [{
                name: 'Conversions',
                data: conversions,
                color: '#cdeac4',
                shadow: {
                  width: 0
                }
              }]

              // passing data to gosquared.
              try {
                _gs('identify', {
                  id: this.getProfile._id,
                  custom: {
                    brands: this.stats.brands,
                    campaigns: this.stats.cta,
                    links: this.stats.links,
                    clicks: this.stats.visitors,
                    conversions: this.stats.clicks,
                    pixels: this.stats.pixels,
                    custom_domains: this.stats.custom_domains

                  }
                })
              } catch (m) {
                console.log(m)
              }
              // customer io.
              try {
                // _cio.identify({
                //   id: this.getProfile._id,
                //   brands: this.stats.brands,
                //   campaigns: this.stats.cta,
                //   links: this.stats.links,
                //   clicks: this.stats.visitors,
                //   conversions: this.stats.clicks,
                //   pixels: this.stats.pixels,
                //   custom_domains: this.stats.custom_domains
                // })
              } catch (e) {

              }

            } else {
              this.alertMessage(response.data.message, 'error')
            }
          }
          this.loader = false
        },
        response => {
          this.loader = false
        }
      )
    },
    scroll () {
      window.onscroll = () => {
        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
        if (bottomOfWindow) {
          this.showDetailView = true
        }
      }
    }
  },
  watch: {
    'getSaveLink.url' (val) {
      if (this.getSaveLink.url && this.getSaveLink.url.length > 8) {
        this.getSaveLink.url = this.urlConcatenation(this.getSaveLink.url)
        this.validations.urlLength = !this.maxLength(this.getSaveLink.url, 1000)
        this.validations.urlValid = !this.isValidURL(this.getSaveLink.url)
      }
    }
  }

}
</script>
<style lang="less">

.first_grid_container {
  display: grid;
  width: 100%;
  grid-template-columns: auto 343px 343px;
  grid-gap: 1.5rem;

  .inner_box {
    height: 225px;
  }

  .quick_shorten_link_section {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 343px;
    grid-gap: 1.5rem;
  }

  @media (max-width: 1280px) {
    .quick_shorten_link_section {
      grid-template-columns: 2fr;
    }
  }
}

@media (max-width: 1560px) {
  .first_grid_container {
    grid-template-columns: 3fr 343px;
  }
}

.second_grid_container {
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 343px;
  grid-gap: 1.5rem;

  .inner_box {
    height: 474px;
  }
}

.third_grid_container {
  display: grid;
  width: 100%;
  grid-template-columns: 343px calc(100% - 687px - 3rem) 343px;
  grid-gap: 1.5rem;

  .inner_box {
    height: 225px;
  }
}
</style>
